/**
* ---------- VARIABLES ----------
========================================================================== */

/**
* ---------- STYLE CLASS ----------
========================================================================== */
.ep-contactpage-wrapper {
  @include size(auto, auto);
  @include padding(rem(15px));
}

.ep-dropzone {
  width: 100%;
  height: auto;
  min-height: rem(48px);
  border: rem(2px) dotted $ep-color-little-light-grey;
  border-radius: rem(4px);
  padding: rem(10px) rem(5px);
}
