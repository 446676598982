* {
  box-sizing: border-box;
}

.ep-thank-you-screen-wrapper {
  height: rem(604px);
  margin: auto;
  background: $ep-color-white;
}

.layout-icon {
  height: rem(150px);
  width: rem(150px);
  border-radius: rem(100px);
  padding: rem(33px);
}
