/*
* COLOR CLASS: ep-text-*color*
* BACKGROUND COLOR CLASS: ep-bg-*color*
*/
@each $color, $value in $colors {
  // COLOR
  .ep-text-#{$color} {
    color: $value !important;
    &:before {
      color: $value !important;
    }
  }

  // BACKGROUND color
  .ep-bg-#{$color} {
    background-color: $value !important;
  }
}
