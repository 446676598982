[class^='epc-icon']:before,
[class*=' epc-icon']:before {
  font: normal normal normal #{$ep-icon-font-size-base}/ 1 'Epilot-Icon-Custom-v2';
  display: flex;
  font-size: inherit;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote('"\\#{$str}"');
}

$epc-icon-questionnaire: unicode(ea02);
$epc-icon-filter-to-check: unicode(ea03);
$epc-icon-shop-location: unicode(ea05);
$epc-icon-frame-s-arrow-down: unicode(ea06);
$epc-icon-pdf: unicode(ea07);
$epc-icon-networking: unicode(ea08);
$epc-icon-menu-4: unicode(ea09);
$epc-icon-mail: unicode(ea0a);
$epc-icon-csv: unicode(ea0b);
$epc-icon-migration: unicode(ea0c);
$epc-icon-calendar-date-2: unicode(ea0d);
$epc-icon-d-delete: unicode(ea0e);
$epc-icon-handover: unicode(ea17);
$epc-icon-automation: unicode(ea1a);
$epc-icon-dots: unicode(ea1b);
$epc-icon-json: unicode(ea1c);
$epc-icon-carsharing: unicode(ea1d);
$epc-icon-certificate: unicode(ea1e);
$epc-icon-charge: unicode(ea1f);
$epc-icon-chp: unicode(ea20);
$epc-icon-district-heat: unicode(ea21);
$epc-icon-gas: unicode(ea22);
$epc-icon-generic: unicode(ea23);
$epc-icon-heating: unicode(ea24);
$epc-icon-heatpump: unicode(ea25);
$epc-icon-house-broadband: unicode(ea26);
$epc-icon-house-electricity: unicode(ea27);
$epc-icon-house-gas: unicode(ea28);
$epc-icon-house-heating: unicode(ea29);
$epc-icon-house-water: unicode(ea2a);
$epc-icon-power: unicode(ea2b);
$epc-icon-smart-home: unicode(ea2c);
$epc-icon-solar: unicode(ea2d);
$epc-icon-storage: unicode(ea2e);
$epc-icon-thermocheck: unicode(ea2f);
$epc-icon-watercheck: unicode(ea30);
$epc-icon-social-sharing: unicode(ea31);
$epc-icon-construction-sign: unicode(ea32);
$epc-icon-office: unicode(ea33);
$epc-icon-charge-b2b: unicode(ea34);
$epc-icon-solar-b2b: unicode(ea35);

.epc-icon-questionnaire::before {
  content: $epc-icon-questionnaire;
}

.epc-icon-filter-to-check::before {
  content: $epc-icon-filter-to-check;
}

.epc-icon-shop-location::before {
  content: $epc-icon-shop-location;
}

.epc-icon-frame-s-arrow-down::before {
  content: $epc-icon-frame-s-arrow-down;
}

.epc-icon-pdf::before {
  content: $epc-icon-pdf;
}

.epc-icon-networking::before {
  content: $epc-icon-networking;
}

.epc-icon-menu-4::before {
  content: $epc-icon-menu-4;
}

.epc-icon-mail::before {
  content: $epc-icon-mail;
}

.epc-icon-csv::before {
  content: $epc-icon-csv;
}

.epc-icon-migration::before {
  content: $epc-icon-migration;
}

.epc-icon-calendar-date-2::before {
  content: $epc-icon-calendar-date-2;
}

.epc-icon-d-delete::before {
  content: $epc-icon-d-delete;
}

.epc-icon-handover::before {
  content: $epc-icon-handover;
}

.epc-icon-automation::before {
  content: $epc-icon-automation;
}

.epc-icon-dots::before {
  content: $epc-icon-dots;
}

.epc-icon-json::before {
  content: $epc-icon-json;
}

.epc-icon-carsharing::before {
  content: $epc-icon-carsharing;
}

.epc-icon-certificate::before {
  content: $epc-icon-certificate;
}

.epc-icon-charge::before {
  content: $epc-icon-charge;
}

.epc-icon-chp::before {
  content: $epc-icon-chp;
}

.epc-icon-district-heat::before {
  content: $epc-icon-district-heat;
}

.epc-icon-gas::before {
  content: $epc-icon-gas;
}

.epc-icon-generic::before {
  content: $epc-icon-generic;
}

.epc-icon-heating::before {
  content: $epc-icon-heating;
}

.epc-icon-heatpump::before {
  content: $epc-icon-heatpump;
}

.epc-icon-house-broadband::before {
  content: $epc-icon-house-broadband;
}

.epc-icon-house-electricity::before {
  content: $epc-icon-house-electricity;
}

.epc-icon-house-gas::before {
  content: $epc-icon-house-gas;
}

.epc-icon-house-heating::before {
  content: $epc-icon-house-heating;
}

.epc-icon-house-water::before {
  content: $epc-icon-house-water;
}

.epc-icon-power::before {
  content: $epc-icon-power;
}

.epc-icon-smart-home::before {
  content: $epc-icon-smart-home;
}

.epc-icon-solar::before {
  content: $epc-icon-solar;
}

.epc-icon-storage::before {
  content: $epc-icon-storage;
}

.epc-icon-thermocheck::before {
  content: $epc-icon-thermocheck;
}

.epc-icon-watercheck::before {
  content: $epc-icon-watercheck;
}

.epc-icon-social-sharing::before {
  content: $epc-icon-social-sharing;
}

.epc-icon-construction-sign::before {
  content: $epc-icon-construction-sign;
}

.epc-icon-office::before {
  content: $epc-icon-office;
}

.epc-icon-charge-b2b::before {
  content: $epc-icon-charge-b2b;
}

.epc-icon-solar-b2b::before {
  content: $epc-icon-solar-b2b;
}
