/**
* ---------- MIXINS CLASS ----------
========================================================================== */
@mixin mixin-setting-font($color, $fontSize, $lineHeight) {
  color: $color;
  font-size: $fontSize;
  line-height: $lineHeight;
}

.ep {
  @include e('txt') {
    @include m('ic') {
      @include mixin-setting-font($ep-color-black, rem(30px), rem(40px));
    }

    @include m('h1') {
      @include mixin-setting-font($ep-color-black, rem(24px), rem(32px));
    }

    @include m('h2') {
      @include mixin-setting-font($ep-color-black, rem(18px), rem(32px));
    }

    @include m('lg') {
      @include mixin-setting-font($ep-color-black, rem(16px), rem(24px));
    }

    @include m('md') {
      @include mixin-setting-font($ep-color-black, rem(14px), rem(24px));
    }

    @include m('sm') {
      @include mixin-setting-font($ep-color-black, rem(12px), rem(16px));
    }

    @include m('required') {
      @include mixin-setting-font($ep-color-black, rem(11px), rem(15px));
    }
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

/**
* ---------- FONT WEIGHT ----------
========================================================================== */
.ep__txt-thin {
  font-weight: 400 !important;
}

.ep__txt-regular {
  font-weight: 500 !important;
}

.ep__txt-semibold {
  font-weight: 600 !important;
}

.ep__txt-bold {
  font-weight: 700 !important;
}

.ep__txt-blackbold {
  font-weight: 800 !important;
}

/**
* ---------- FONT WEIGHT ----------
========================================================================== */
.ep-font-size-16 {
  font-size: rem(16px) !important;
}
.ep-font-size-18 {
  font-size: rem(18px) !important;
}
.ep-font-size-20 {
  font-size: rem(20px) !important;
}

/**
* ---------- TEXT-TRANSFORM ----------
*
* Bootstrap's class existed:
*  text-uppercase
*  text-lowercase
*  text-capitalize
*/
.ep-text-first-upercase {
  &:first-letter {
    text-transform: uppercase;
  }
}

/**
* ---------- TEXT DECORATION ----------
*/
.ep-text-decoration-underline {
  text-decoration: underline !important;
}

/* ---------- LINE HEIGHT ----------
========================================================================== */
.ep-line-height-32 {
  line-height: rem(32px) !important;
}

/* ---------- TEXT-OVERFLOW ----------
========================================================================== */
.ep-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* ---------- TEXT-OVERFLOW ----------
========================================================================== */
.ep-white-space-nowrap {
  white-space: nowrap !important;
}
