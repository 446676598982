/**
* ---------- VARIABLES ----------
========================================================================== */
$ep-contact-wrapper-icon-size: rem(120px);

/**
* ---------- STYLE CLASS ----------
========================================================================== */
.ep-success-wrapper-icon {
  @include size($ep-contact-wrapper-icon-size, $ep-contact-wrapper-icon-size);
  @include border-top-radius(50%);
  @include border-bottom-radius(50%);
  background: transparentize($ep-color-dark-blue, 0.97);
  display: inline-block;
  i {
    @include position(relative, rem(32px) null null rem(32px));
    font-size: rem(48px);
  }
}
