/**
* ---------- VARIABLES ----------
========================================================================== */
$ep-customerjourneypage-wrapper-width: rem(1329px);
$ep-customerjourneypage-wrapper-height: rem(560px);
$ep-customerjourneypage-webshop-wrapper-box-shadow: 0 0 rem(30px) 0 rgba(0, 0, 0, 0.27);
$ep-customerjourneypage-wrapper-background-color: $ep-color-white;
$ep-customerjourney-wrapper-icon-size: rem(120px);

/**
* ---------- STYLE CLASS ----------
========================================================================== */
.ep-customerjourneypage-wrapper {
  @include size(auto, auto);
  @include padding(0);
  background: $ep-customerjourneypage-wrapper-background-color;
  overflow: hidden;

  .ep-contact-wrapper-icon {
    @include size($ep-customerjourney-wrapper-icon-size, $ep-customerjourney-wrapper-icon-size);
    @include border-top-radius(50%);
    @include border-bottom-radius(50%);
    @include margin(rem(27px) null null null);
    background: $ep-color-light-grey;
    display: inline-block;

    i {
      @include position(relative, rem(32px) null null null);
      font-size: rem(48px);
    }
  }
}

.ep-customerjourneypage-question-wrapper {
  @include size(100%, rem(319px));
}

.ep-customerjourneypage-tellus-wrapper {
  @include size(100%, rem(88px));

  @include e('text') {
    height: rem(56px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ep-customerjourneypage {
  @include e('question') {
    @include size(auto, auto);
    text-align: center;
    margin: 0 auto;
    min-height: rem(318px);
  }
}

.ep-wrapper-icon-title-question {
  display: flex;
  justify-content: center;
  align-items: center;
  height: rem(150px);
  width: rem(150px);
  border-radius: 50%;
}

.item-housing-unit {
  text-align: left;
  padding-left: rem(8px) !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .pt-description-wrap {
    max-width: rem(300px);
  }
}

@media (max-width: 768px) {
  .ep-customerjourneypage-tellus-wrapper {
    @include e('text') {
      height: auto;
    }
  }

  .order-contact-form {
    order: 1;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: rem(600px)) {
  .wrapper-item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .item-dropdown {
      grid-row: 1;
      grid-column-start: 1;
      grid-column-end: 3;
      max-width: rem(512px);
    }

    .item-dropdown-other-option {
      grid-row: 1;
      grid-column-start: 1;
      grid-column-end: 2;
      max-width: rem(265px);
      padding-right: rem(16px) !important;
    }

    .item-other-input {
      max-width: rem(265px);
      padding-left: rem(0px) !important;
    }

    .item-number-spinner {
      max-width: rem(265px);
    }

    .item-input {
      max-width: rem(265px);
    }

    .item-total-number {
      max-width: rem(265px);
      text-align: right !important;
      padding-right: rem(8px) !important;
    }

    .item-remove {
      grid-row: 1;
      grid-column-start: 3;
      max-width: rem(265px);
      text-align: right !important;
      padding-right: rem(8px) !important;
    }
  }
}

/*----- NAVIGATION ICON -----*/
.ep-navigation-icon {
  color: $ep-color-dark-grey;
  cursor: pointer;
  opacity: 1;
}

.ep-navigation-icon--index {
  z-index: $ep-zindex-level-2;
}

.ep-navigation-icon--hover {
  font-size: rem(20px);
  @extend .ep-navigation-icon;

  &:hover {
    color: $ep-color-black;
  }
}

.ep-navigation-icon__arrow {
  font-size: rem(20px);
}
