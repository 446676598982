.ep-slider {
  .pt-slider-track,
  .pt-slider-progress {
    top: rem(3px);
    right: 0;
    left: 0;
    height: rem(10px);
    position: absolute;
    border-radius: rem(12px);
    opacity: 0.5;
  }

  .pt-slider-handle {
    position: absolute;
    top: -1;
    left: 0;
    border-radius: rem(12px);
    cursor: pointer;
    width: rem(18px);
    height: rem(18px);
    outline: none;
  }
}
