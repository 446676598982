@import '~@blueprintjs/core/lib/scss/variables';

/*
* TOOLTIP
*/
.ep-tooltip {
  .#{$ns}-popover-content {
    color: $ep-color-black;
    background: $ep-color-white;
    font-size: rem(14px);
    font-weight: 500;
  }

  .#{$ns}-popover-arrow {
    margin: rem(-2px 0);
  }

  .#{$ns}-popover-arrow-fill {
    fill: $ep-color-white;
  }

  &.ep-tooltip-width-300 {
    .#{$ns}-popover-content {
      max-width: rem(300px);
    }
  }
}

/*
* TOOLTIP DARK
*/
.ep-tooltip-dark {
  &.pt-popover {
    .#{$ns}-popover-content {
      padding: rem(10px) rem(12px);
      color: $ep-color-white;
      background: transparentize($ep-color-light-black, 0.15);
      font-size: rem(14px);
      font-weight: 500;
    }

    .#{$ns}-popover-arrow {
      &:before {
        box-shadow: none;
      }
    }

    .#{$ns}-popover-arrow-fill {
      fill: transparentize($ep-color-light-black, 0.2);
    }

    &.ep-tooltip-width-500 {
      .#{$ns}-popover-content {
        max-width: rem(500px);
      }
    }
  }
}
