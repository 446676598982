.pt-checkbox {
  .pt-control-indicator {
    background-color: $ep-color-white !important;
    box-sizing: unset;
    -ms-box-sizing: unset;
    -moz-box-sizing: unset;
    -webkit-box-sizing: unset;

    &:focus {
      outline: transparent auto 0 !important;
    }
  }

  &.pt-disabled {
    .pt-control-indicator {
      background-color: $ep-color-light-grey !important;
    }
  }
}

.pt-control input:focus ~ .pt-control-indicator {
  outline: transparent auto 0 !important;
}
