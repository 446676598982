.ep-custom-collapse {
  transition: none;
  position: relative;
  z-index: $ep-zindex-level-1;

  &:focus,
  &:focus-within {
    z-index: $ep-zindex-level-2;
  }

  .pt-collapse-body {
    overflow: visible !important;
  }
}
