/* PRIMARY COLOR
========================================================================== */
$ep-color-white: #ffffff;
$ep-color-black: #000000;
$ep-color-light-black: #222222;
$ep-color-blue: #039be5;
$ep-color-dark-blue: #4084e8;
$ep-color-light-grey: #f9f9f9;
$ep-color-little-light-grey: #d8d8d8;
$ep-color-grey: #ababab;
$ep-color-little-dark-grey: #b5b5b5;
$ep-color-dark-grey: #8a8a8a;
$ep-color-green: #6bbf16;
$ep-color-bright-green: #ace539;
$ep-color-red: #f44336;
$ep-color-purple: #a942b8;
$ep-color-orange: #ff7000;
$ep-color-hover: #f5f5f5;
$ep-color-yellow: #fed330;
$ep-color-transparent: transparent;

// Use in _color.scss
$colors: () !default;
$colors: map-merge(
  (
    'white': $ep-color-white,
    'black': $ep-color-black,
    'light-black': $ep-color-light-black,
    'blue': $ep-color-blue,
    'dark-blue': $ep-color-dark-blue,
    'light-grey': $ep-color-light-grey,
    'little-light-grey': $ep-color-little-light-grey,
    'grey': $ep-color-grey,
    'little-dark-grey': $ep-color-little-dark-grey,
    'dark-grey': $ep-color-dark-grey,
    'green': $ep-color-green,
    'bright-green': $ep-color-bright-green,
    'red': $ep-color-red,
    'purple': $ep-color-purple,
    'orange': $ep-color-orange,
    'hover': $ep-color-hover,
    'yellow': $ep-color-yellow,
    'transparent': $ep-color-transparent
  ),
  $colors
);

/* PREFIX BROWSER
========================================================================== */
$ep-browser: 'webkit', 'moz', 'ms', 'o', 'spec';

/* FONT PARAMETER 
========================================================================== */
$ep-file-format: eot woff2 woff ttf svg;
$ep-icon-font-size-base: 14px !default;

/* Z-INDEX
========================================================================== */
$ep-zindex-level-1: 9998;
$ep-zindex-level-2: 9999;
$ep-zindex-level-3: 10000;
$ep-zindex-level-4: 10001;
$ep-zindex-level-5: 10002;
$ep-zindex-level-6: 10003;
$ep-zindex-level-7: 100010;
$ep-zindex-level-8: 100011;
