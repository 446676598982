/*
* DATE INPUT
*/
/**
* ---------- VARIABLES ----------
========================================================================== */
$ep-color-react-calendar: #e6e6e6;
$ep-color-react-calendar-tile-hasActive: #969696;
$ep-box-shadow-react-calendar: 0 0 8px 0 rgba(0, 0, 0, 0.5);
$ep-font-size-icon: rem(20px);

/**
* ---------- STYLE DATE PICKER ----------
========================================================================== */
.ep-react-date-input {
  width: rem(255px);
  min-width: rem(80px);
  height: rem(56px);
  border-radius: rem(4px);
  max-width: rem(400px);
  border-color: $ep-color-little-dark-grey !important;
  &:focus,
  &:focus-within {
    z-index: $ep-zindex-level-2;
  }

  .react-calendar__tile--active {
    &:enabled {
      &:hover {
        color: $ep-color-react-calendar;
      }

      &:focus {
        color: $ep-color-react-calendar;
      }
    }
  }

  .react-calendar__navigation__label {
    font-weight: 700 !important;
    font-size: rem(14px);
    padding: 0;
  }

  .react-date-picker {
    display: inline-flex;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    * {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      &:before {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      &:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
    }
  }

  .react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }

  .react-date-picker__wrapper {
    display: flex;
    border: thin solid $ep-color-little-dark-grey;
    width: 100%;
    max-width: rem(255px);
    min-width: rem(80px);
    height: rem(56px);
    border-radius: rem(4px);

    button:focus {
      outline: none !important;
    }
  }

  .react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    display: flex;
    padding: 0 2px;
    align-items: baseline;
    box-sizing: content-box;

    input:focus {
      outline: none !important;
    }
  }

  .react-date-picker-center {
    display: block;
    justify-content: center;
    display: block;
  }

  .react-date-picker__inputGroup__divider {
    padding: 1px 0;
    font-size: rem(14px);
    font-weight: 700;
  }

  .react-date-picker__inputGroup__input {
    min-width: 0.65em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font-weight: 400;
    box-sizing: content-box;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:invalid {
      background: transparent;
    }
  }

  .react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }

  .react-date-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;

    &:enabled {
      cursor: pointer;
    }

    &:disabled {
      svg {
        g {
          stroke: #6d6d6d;
        }
      }
    }

    svg {
      display: inherit;
    }
  }

  .react-date-picker {
    .react-date-picker__calendar-button {
      &.ep-icon-calendar-grid-58:before {
        font-size: $ep-font-size-icon !important;
      }
    }

    .react-date-picker__clear-button {
      &.ep-icon-simple-remove:before {
        font-size: $ep-font-size-icon !important;
      }
    }
  }

  .react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 6;

    .react-calendar {
      border-width: thin;
      font-size: rem(13px);
    }
  }

  .react-date-picker__calendar--closed {
    display: none;
  }

  // Style input of datepicker in Contact Form
  &.ep-datepicker-input {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: rem(32px);
    background-color: $white;

    .react-date-picker__wrapper {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      height: rem(32px);

      .react-date-picker__inputGroup {
        font-size: rem(14px);
        font-weight: 600;
      }
    }
  }
}

/**
* ---------- STYLE CALENDAR ----------
========================================================================== */
.ep-calendar-date-input {
  border-radius: rem(4px);
  margin-top: rem(2px);
  max-width: rem(255px);
  min-width: rem(80px);
}

.react-calendar {
  width: 100%;
  max-width: rem(255px);
  background: $ep-color-white;
  box-shadow: $ep-box-shadow-react-calendar;
  -moz-box-shadow: $ep-box-shadow-react-calendar;
  -webkit-box-shadow: $ep-box-shadow-react-calendar;
  font-family: Arial, Helvetica, sans-serif;
  line-height: rem(1.125px);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &:before {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    &:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.react-calendar__navigation {
  height: rem(44px);
  margin-bottom: 1em;
  font-size: rem(16px);

  button {
    min-width: rem(30px);
    background: none;

    &:enabled {
      &:hover {
        background-color: $ep-color-react-calendar;
      }

      &:focus {
        background-color: $ep-color-react-calendar;
      }
    }
  }

  button[disabled] {
    background-color: #f0f0f0;
  }
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  color: #849096;
  padding: 0.5em;

  abbr[data-original-title],
  abbr[title] {
    cursor: default;
    text-decoration: none;
  }
}

.react-calendar__month-view__weekNumbers {
  font-weight: 600;

  .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(13px);
    padding: rem(14px) rem(8px);
  }
}

.react-calendar__month-view__days__day--weekend {
  color: $ep-color-light-black;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: $ep-color-react-calendar-tile-hasActive;
}

.react-calendar__year-view,
.react-calendar__tile,
.react-calendar__century-view {
  .react-calendar__tile {
    padding: rem(20px) rem(0px);
  }
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: rem(14px) rem(8px);
  background: none;

  &:disabled {
    background-color: #e6e5e5;
    color: #849096;
    cursor: not-allowed;
  }

  &:enabled {
    &:hover {
      background-color: $ep-color-react-calendar;
    }

    &:focus {
      background-color: $ep-color-react-calendar;
    }
  }
}

.react-calendar__tile--hasActive {
  background: $ep-color-react-calendar-tile-hasActive;
  font-weight: 700;
  color: $ep-color-react-calendar;

  &:enabled {
    &:hover {
      background: $ep-color-react-calendar-tile-hasActive;
    }

    &:focus {
      background: $ep-color-react-calendar-tile-hasActive;
    }
  }
}

.react-calendar__tile--active {
  background: transparent;
  color: $ep-color-light-black;
  font-weight: bold;

  &:enabled {
    &:hover {
      background: $ep-color-react-calendar-tile-hasActive;
    }

    &:focus {
      background: $ep-color-react-calendar-tile-hasActive;
    }
  }
}

.react-calendar--selectRange {
  .react-calendar__tile--hover {
    background-color: $ep-color-react-calendar;
  }
}

.react-date-picker__inputGroup__month__set__width {
  width: rem(140px) !important;
  font-size: rem(14px);
}
