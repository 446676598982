.ep-switch {
  display: flex;

  .ep-switch-button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid $ep-color-little-light-grey;
    padding: 0 rem(8px);

    &[data-position='left'] {
      border-right: none;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    &[data-position='right'] {
      border-left: none;
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }

    &:focus {
      outline: none;
    }
  }
}
