/*
Font Epilot Icons full
*/
@include font-face(
  'Epilot-Icon',
  '../fonts/icon.nucleo/ep-icon-full',
  $file-formats: $ep-file-format
);
@import 'iconfonts/_ep-icon';

@include font-face(
  'Epilot-Icon-Custom-v2',
  '../fonts/icon.custom.v2/ep-iconset-custom',
  $file-formats: $ep-file-format
);
@import 'iconfonts/_epc-icon';
