/* ================ HEIGHT ====================
** Class: ep-height-{value}*/
$height: 8, 33, 48, 56;

@each $value in $height {
  .ep-height-#{$value} {
    height: rem(#{$value}px) !important;
  }
}

/* ================ MIN-HEIGHT ====================
** Class: ep-min-height-{value}*/
$minHeight: 70, 114, 130;

@each $value in $minHeight {
  .ep-min-height-#{$value} {
    min-height: rem(#{$value}px) !important;
  }
}

/* ================ WIDTH ====================
** Class: ep-width-{value}*/
$width: 62, 350;

@each $value in $width {
  .ep-width-#{$value} {
    width: rem(#{$value}px) !important;
  }
}

/* ================ WIDTH ====================
** Class: ep-w-{value}*/
$w: 10, 80;
@each $value in $w {
  .ep-w-#{$value} {
    width: #{$value}% !important;
  }
}

/* ================ MIN-WIDTH ====================
** Class: ep-min-width-{value}*/
$minWidth: 60, 80, 130;

@each $value in $minWidth {
  .ep-min-width-#{$value} {
    min-width: rem(#{$value}px) !important;
  }
}

/* ================ MAX-WIDTH ====================
** Class: ep-max-width-{value}*/
$maxWidth: 300;

@each $value in $maxWidth {
  .ep-max-width-#{$value} {
    max-width: rem(#{$value}px) !important;
  }
}
