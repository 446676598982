/*----- COLOR -----*/
$blue: $ep-color-blue;
$yellow: $ep-color-yellow;
$green: $ep-color-green;
$red: $ep-color-red;
$gray-100: $ep-color-light-grey;
$gray-800: $ep-color-dark-grey;
$black: $ep-color-light-black;

$colors: (
  (
    'blue': $blue,
    'red': $red,
    'yellow': $yellow,
    'green': $green,
    'gray-dark': $gray-800
  )
);

/*----- THEME COLOR -----*/
$primary: $blue;
$warning: $yellow;
$danger: $red;
$success: $green;
$light: $gray-100;
$dark: $gray-800;

$theme-colors: (
  'primary': $primary,
  'danger': $danger,
  'warning': $warning,
  'success': $success,
  'light': $light,
  'dark': $dark
);

/*----- BORDER RADIUS -----*/
$border-radius: 0.25rem;
$border-radius-lg: 0.5rem;

/*----- FONT WEIGHT -----*/
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
