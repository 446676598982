.ep {
  // MARGIN
  @include m('m-0') {
    margin: 0 !important;
  }
  @include m('m-8') {
    margin: rem(8px) !important;
  }
  @include m('m-16') {
    margin: rem(16px) !important;
  }
  @include m('m-24') {
    margin: rem(24px) !important;
  }
  @include m('m-32') {
    margin: rem(32px) !important;
  }
  @include m('m-48') {
    margin: rem(48px) !important;
  }

  // MARGIN X-AXIS
  @include m('mx-0') {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  @include m('mx-8') {
    margin-left: rem(8px) !important;
    margin-right: rem(8px) !important;
  }
  @include m('mx-16') {
    margin-left: rem(16px) !important;
    margin-right: rem(16px) !important;
  }
  @include m('mx-32') {
    margin-left: rem(32px) !important;
    margin-right: rem(32px) !important;
  }

  // MARGIN Y-AXIS
  @include m('my-0') {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  @include m('my-4') {
    margin-top: rem(4px) !important;
    margin-bottom: rem(4px) !important;
  }
  @include m('my-8') {
    margin-top: rem(8px) !important;
    margin-bottom: rem(8px) !important;
  }
  @include m('my-16') {
    margin-top: rem(16px) !important;
    margin-bottom: rem(16px) !important;
  }
  @include m('my-24') {
    margin-top: rem(24px) !important;
    margin-bottom: rem(24px) !important;
  }
  @include m('my-32') {
    margin-top: rem(32px) !important;
    margin-bottom: rem(32px) !important;
  }

  // MARGIN TOP
  @include m('mt-0') {
    margin-top: 0 !important;
  }
  @include m('mt-8') {
    margin-top: rem(8px) !important;
  }
  @include m('mt-16') {
    margin-top: rem(16px) !important;
  }
  @include m('mt-24') {
    margin-top: rem(24px) !important;
  }
  @include m('mt-32') {
    margin-top: rem(32px) !important;
  }
  @include m('mt-48') {
    margin-top: rem(48px) !important;
  }
  @include m('mt-56') {
    margin-top: rem(56px) !important;
  }

  // MARGIN RIGHT
  @include m('mr-0') {
    margin-right: 0 !important;
  }
  @include m('mr-8') {
    margin-right: rem(8px) !important;
  }
  @include m('mr-16') {
    margin-right: rem(16px) !important;
  }
  @include m('mr-24') {
    margin-right: rem(24px) !important;
  }
  @include m('mr-32') {
    margin-right: rem(32px) !important;
  }

  // MARGIN BOTTOM
  @include m('mb-0') {
    margin-bottom: 0 !important;
  }
  @include m('mb-8') {
    margin-bottom: rem(8px) !important;
  }
  @include m('mb-16') {
    margin-bottom: rem(16px) !important;
  }
  @include m('mb-24') {
    margin-bottom: rem(24px) !important;
  }
  @include m('mb-32') {
    margin-bottom: rem(32px) !important;
  }

  // MARGIN LEFT
  @include m('ml-0') {
    margin-left: 0 !important;
  }
  @include m('ml-4') {
    margin-left: rem(4px) !important;
  }
  @include m('ml-8') {
    margin-left: rem(8px) !important;
  }
  @include m('ml-16') {
    margin-left: rem(16px) !important;
  }
  @include m('ml-24') {
    margin-left: rem(24px) !important;
  }
  @include m('ml-32') {
    margin-left: rem(32px) !important;
  }

  // PADDING
  @include m('p-0') {
    padding: 0 !important;
  }
  @include m('p-8') {
    padding: rem(8px) !important;
  }
  @include m('p-16') {
    padding: rem(16px) !important;
  }
  @include m('p-24') {
    padding: rem(24px) !important;
  }
  @include m('p-32') {
    padding: rem(32px) !important;
  }

  // PADDING X-AXIS
  @include m('px-0') {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @include m('px-8') {
    padding-left: rem(8px) !important;
    padding-right: rem(8px) !important;
  }
  @include m('px-16') {
    padding-left: rem(16px) !important;
    padding-right: rem(16px) !important;
  }
  @include m('px-32') {
    padding-left: rem(32px) !important;
    padding-right: rem(32px) !important;
  }

  // PADDING Y-AXIS
  @include m('py-0') {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  @include m('py-8') {
    padding-top: rem(8px) !important;
    padding-bottom: rem(8px) !important;
  }
  @include m('py-12') {
    padding-top: rem(12px) !important;
    padding-bottom: rem(12px) !important;
  }
  @include m('py-16') {
    padding-top: rem(16px) !important;
    padding-bottom: rem(16px) !important;
  }
  @include m('py-24') {
    padding-top: rem(24px) !important;
    padding-bottom: rem(24px) !important;
  }

  // PADDING TOP
  @include m('pt-0') {
    padding-top: 0 !important;
  }
  @include m('pt-8') {
    padding-top: rem(8px) !important;
  }
  @include m('pt-16') {
    padding-top: rem(16px) !important;
  }
  @include m('pt-24') {
    padding-top: rem(24px) !important;
  }
  @include m('pt-32') {
    padding-top: rem(32px) !important;
  }

  // PADDING RIGHT
  @include m('pr-0') {
    padding-right: 0 !important;
  }
  @include m('pr-8') {
    padding-right: rem(8px) !important;
  }
  @include m('pr-16') {
    padding-right: rem(16px) !important;
  }
  @include m('pr-24') {
    padding-right: rem(24px) !important;
  }
  @include m('pr-32') {
    padding-right: rem(32px) !important;
  }

  // PADDING BOTTOM
  @include m('pb-0') {
    padding-bottom: 0 !important;
  }
  @include m('pb-8') {
    padding-bottom: rem(8px) !important;
  }
  @include m('pb-16') {
    padding-bottom: rem(16px) !important;
  }
  @include m('pb-24') {
    padding-bottom: rem(24px) !important;
  }
  @include m('pb-32') {
    padding-bottom: rem(32px) !important;
  }

  // PADDING LEFT
  @include m('pl-0') {
    padding-left: 0 !important;
  }
  @include m('pl-8') {
    padding-left: rem(8px) !important;
  }
  @include m('pl-16') {
    padding-left: rem(16px) !important;
  }
  @include m('pl-24') {
    padding-left: rem(24px) !important;
  }
  @include m('pl-32') {
    padding-left: rem(32px) !important;
  }
}
