.payment__option--wrapper {
  @media (min-width: 768px) {
    max-width: 55%;
  }

  .payment__option--radio {
    height: rem(48px);
    background-color: $ep-color-white;
    box-shadow: rem(0) rem(2px) rem(6px) rem(0) rgba(0, 0, 0, 0.2);

    .pt-radio {
      margin: rem(0) rem(20px) rem(0);
      letter-spacing: rem(0.5px);

      .pt-control-indicator {
        top: rem(3px);
        height: rem(16px);
        width: rem(16px);
      }
    }
  }
}
