/**
* ---------- VARIABLES ----------
========================================================================== */
$ep-button-size-height: rem(36px);
$ep-button-padding: rem(10px);

$ep-btn-height-small: rem(32px);
$ep-btn-pd-x-small: rem(16px);
$ep-btn-pd-y-small: rem(4px);

$ep-btn-height-normal: rem(48px);
$ep-btn-pd-x-normal: rem(24px);
$ep-btn-pd-y-normal: rem(8px);

$ep-btn-height-large: rem(56px);
$ep-btn-pd-x-large: rem(32px);
$ep-btn-pd-y-large: rem(16px);

$ep-btn-height-small: rem(32px);
$ep-btn-pd-x-small: rem(16px);
$ep-btn-pd-y-small: rem(4px);

$ep-btn-height-normal: rem(48px);
$ep-btn-pd-x-normal: rem(24px);
$ep-btn-pd-y-normal: rem(8px);

$ep-btn-height-large: rem(56px);
$ep-btn-pd-x-large: rem(32px);
$ep-btn-pd-y-large: rem(16px);

/**
* ---------- STYLE CLASS ----------
========================================================================== */
.ep-button {
  @include size(auto, $ep-button-size-height);
  @include padding(null $ep-button-padding null $ep-button-padding);
  @include border-color($ep-color-little-dark-grey);
  @include border-style(solid);
  @include border-width(rem(1px));
  color: $ep-color-black;
  cursor: pointer;

  &:focus {
    outline: none;
    @include border-color(transparent);
  }
  &[disabled] {
    cursor: not-allowed;
  }
}

.ep {
  @include e('btn') {
    @include m('small') {
      height: rem($ep-btn-height-small);
      width: auto;
      padding: $ep-btn-pd-y-small $ep-btn-pd-x-small;
    }

    @include m('normal') {
      height: rem($ep-btn-height-normal);
      width: auto;
      padding: $ep-btn-pd-y-normal $ep-btn-pd-x-normal;
    }

    @include m('large') {
      height: rem($ep-btn-height-large);
      width: auto;
      padding: $ep-btn-pd-y-large $ep-btn-pd-x-large;
    }
  }
}
