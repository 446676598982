@import '~@blueprintjs/core/lib/scss/variables';
/**
* ---------- VARIABLES ----------
========================================================================== */
$ep-input-size-height: rem(32px);
$ep-input-padding: rem(10px);
$ep-input-border-radius: rem(4px);

/**
* ---------- STYLE CLASS ----------
========================================================================== */
.ep-input {
  @include size(100%, $ep-input-size-height);
  @include padding(null $ep-input-padding null $ep-input-padding);
  @include border-color($ep-color-little-dark-grey);
  @include border-top-radius($ep-input-border-radius);
  @include border-bottom-radius($ep-input-border-radius);
  @include border-style(solid);
  @include border-width(rem(1px));
  font-size: rem(11px);
  color: $ep-color-black;

  &:focus {
    outline: none;
    @include border-color($ep-color-blue);
  }

  &[disabled] {
    background: $ep-color-light-grey;
  }
}

input::-ms-clear {
  display: none;
}

textarea::-webkit-scrollbar {
  width: 0;
}

/**
* ---------- INPUT DROPDOWN ----------
========================================================================== */
.ep-dropdown-input {
  position: absolute;
  right: 0;
  z-index: $ep-zindex-level-2;
  background: $ep-color-white;
  max-width: rem(300px);
  min-width: rem(80px);
  width: 100%;
  box-shadow: 0 0 rem(10px) 0 rgba(34, 34, 34, 0.25);
  border-radius: rem(4px);
  margin-top: rem(10px);

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: rem(-7px);
    left: 90%;
    width: 0;
    border-width: 0 rem(7px) rem(7px);
    border-style: solid;
    border-color: $ep-color-white transparent;
  }

  .ep-dropdown-input-item {
    height: rem(40px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: rem(15px);
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

/**
* ---------- CUSTOM - BLUEPRINT INPUT ----------
========================================================================== */
.#{$ns}-input {
  box-shadow: none;
  @include border-color($ep-color-little-dark-grey);
  @include border-top-radius($ep-input-border-radius);
  @include border-bottom-radius($ep-input-border-radius);
  @include border-style(solid);
  @include border-width(rem(1px));
  font-size: rem(12px);
  color: $ep-color-black;

  &:focus {
    box-shadow: none;
    @include border-color($ep-color-blue);
  }

  &::-webkit-input-placeholder {
    /* Chrome */
    color: $ep-color-dark-grey;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $ep-color-dark-grey;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $ep-color-dark-grey;
  }

  &:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: $ep-color-dark-grey;
  }
}
