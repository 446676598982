@import '~@blueprintjs/core/lib/scss/variables';

/**
* ---------- VARIABLES ----------
========================================================================== */
$ep-autocomplete-border-radius: rem(4px);

$ep-autocomplete-font-family: 'Montserrat-600';
$ep-autocomplete-font-size: rem(12px);

/**
* ---------- STYLE CLASS ----------
========================================================================== */
.ep-autocomplete {
  @include size(100%, auto);
  font-size: rem(12px);
  color: $ep-color-black;
  font-family: inherit;

  * {
    font-family: inherit;
  }

  .#{$ns}-transition-container {
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &:after {
    content: #0ebeff;
    height: 0;
    width: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $ep-color-red;
    position: absolute;
    right: 6px;
    top: 8px;
    transition: all 0.3s linear;
  }

  .ep-autocomplete-input {
    .#{$ns}-input {
      font-weight: 500;
    }
    &.#{$ns}-disabled input {
      background: $ep-color-light-grey;
      color: $ep-color-black;
    }
  }
}

.ep-custom-autocomplete {
  &:focus,
  &:focus-within {
    z-index: $ep-zindex-level-7;
  }
}

/**
* ---------- CUSTOM CSS FOR BLUEPRINT ----------
========================================================================== */
.autocomplete-popover-custom {
  width: 100%;

  .#{$ns}-popover-arrow {
    right: 20px;
    left: auto !important;
  }
}

.autocomplete-popover-custom-for-question {
  min-width: rem(80px);
  max-width: rem(300px);
}

/*
* MENU ITEM
*/
.pt-menu-item.pt-intent-primary.pt-active {
  background-color: $ep-color-hover;
}

.pt-menu-item:hover {
  background-color: $ep-color-hover;
}

.pt-menu {
  padding: 0;
  max-height: rem(200px);
  min-width: rem(100px);
  overflow-y: auto;
  overflow-x: auto;
}
