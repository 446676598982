.ep--tab-list {
  * {
    outline: none;
  }

  .pt-tab {
    @include margin(0);
    text-align: center;
    font-size: rem(11px);
    color: $ep-color-white;
  }

  .pt-tab-panel {
    @include margin(rem(0));
  }
}

.ep-tabs {
  @extend .ep--tab-list;

  .pt-tab {
    @include size(50%, auto);
  }
}

.ep-tabs-1 {
  @extend .ep--tab-list;

  .pt-tab {
    @include size(100%, auto);
  }
}
