/**
* ---------- MIXINS CLASS ----------
========================================================================== */
@mixin mixin-setting-line($height, $width, $background) {
  height: $height;
  width: $width;
  background: $background;
}

.ep {
  @include p('line-divider') {
    @include p('h2') {
      @include mixin-setting-line(rem(2px), 100%, $ep-color-little-dark-grey);
    }
  }
}

/*
* LINE CUSTOM
*/
.ep-hr-custom {
  height: rem(16px);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.0001) 0%,
    rgba(0, 0, 0, 0.25) 15.73%,
    transparent 16.42%,
    transparent 100%
  );
  opacity: 1;
}
