// @alias element
@mixin e($element) {
  &__#{$element} {
    @content;
  }
}

// @alias modifier
@mixin m($modifier) {
  &--#{$modifier} {
    @content;
  }
}

// @alias parameter
@mixin p($parameter) {
  &-#{$parameter} {
    @content;
  }
}
