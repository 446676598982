@import '~@blueprintjs/core/lib/scss/variables';

/**
* ---------- VARIABLES ----------
========================================================================== */
$ep-dropdown-size-height: rem(32px);
$ep-dropdown-padding: rem(10px);
$ep-dropdown-border-radius: rem(4px);
$ep-dropdown-font-size: rem(14px);

/**
* ---------- STYLE CLASS ----------
========================================================================== */
.ep-dropdown {
  @include size(100%, $ep-input-size-height);
  font-size: rem($ep-dropdown-font-size);
  color: $ep-color-black;
  font-family: inherit;

  * {
    font-family: inherit;
  }

  .#{$ns}-transition-container {
    width: 100%;
    z-index: $ep-zindex-level-3;
  }
}

/**
* ---------- CUSTOM CSS FOR BLUEPRINT ----------
========================================================================== */
.dropdown-popover-custom {
  width: 100%;
  min-width: rem(200px);

  .#{$ns}-popover-arrow {
    right: 20px;
    left: auto !important;
  }
  .#{$ns}-popover-content {
    border-radius: rem(4px);
    overflow: hidden;
    .#{$ns}-menu {
      padding: rem(8px 0);
      .#{$ns}-menu-item {
        padding: rem(8px 16px);
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}

.dropdown-button {
  @include size(100%, $ep-input-size-height);
  background-color: $ep-color-white !important;
  box-shadow: none !important;
  @include border-color($ep-color-little-dark-grey);
  @include border-top-radius($ep-input-border-radius);
  @include border-bottom-radius($ep-input-border-radius);
  @include border-style(solid);
  @include border-width(rem(1px));
  font-size: rem($ep-dropdown-font-size);
  color: $ep-color-black !important;
  justify-content: space-between;

  &:focus {
    box-shadow: none;
    outline: none;
    @include border-color($ep-color-blue);
  }
}
