.ep-webshopv2-product-wrapper {
  min-height: rem(76px);
}

.view-component-button {
  border: rem(1px) solid rgba(34, 34, 34, 0.16);
  border-radius: rem(8px);
  width: rem(148px);
  box-sizing: border-box;
  height: rem(28px);
  cursor: pointer;
}

.w-82 {
  width: 82%;
}

@include media-breakpoint-down(sm) {
  .ep-webshop-scroll-block {
    height: unset !important;
  }
}
