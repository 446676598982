/**
* ---------- VARIABLES ----------
========================================================================== */
$ep-webshoppage-wrapper-width: rem(440px);
$ep-webshoppage-wrapper-height: rem(560px);
$ep-webshop-wrapper-icon-size: rem(120px);

/**
* ---------- STYLE CLASS ----------
========================================================================== */
.ep-webshoppage-wrapper {
  @include size(auto, auto);
  @include padding(rem(0px) rem(15px) rem(15px) rem(15px));
  overflow: hidden;

  .ep-contact-wrapper-icon {
    @include size($ep-webshop-wrapper-icon-size, $ep-webshop-wrapper-icon-size);
    @include border-top-radius(50%);
    @include border-bottom-radius(50%);
    @include margin(null null null null);
    background: $ep-color-light-grey;
    display: inline-block;

    i {
      @include position(relative, rem(32px) null null null);
      font-size: rem(48px);
    }
  }

  .ep-webshoppage-total-wrapper {
    @include size(auto, auto);
    min-height: rem(88px);
    @include margin(rem(5px) null null null);
  }
}

.ep-webshop-product-wrapper {
  height: rem(76px);
}

.ep-webshop-product-wrapper-customer-journey {
  height: rem(99px);
}

.ep-webshop-selectpackage-wrapper {
  @include margin(null null null null);

  background: $ep-color-light-grey;

  .ep-webshop-selectpackage-header {
    @include size(auto, rem(76px));
  }
}

.ep-webshop-contactform-wrapper {
  .ep-webshop-contactform-quote {
    .ep-webshop-contactform-backbutton {
      @include margin(rem(10px 0 0 0));
    }

    .ep-webshop-contactform-total {
      font-size: rem(30px);
    }
  }

  .ep-webshop-contactform-form {
    @include padding(0 0 rem(152px) 0);
  }
}
