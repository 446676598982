::-webkit-scrollbar {
  width: rem(8px) !important;
  height: rem(8px) !important;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(132, 144, 150, 0.3) !important;
  @include prefixer(border-radius, rem(4px) !important, $ep-browser);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(132, 144, 150, 0.6) !important;
}
