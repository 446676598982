@charset "UTF-8";

/// Builds the `src` list for an `@font-face` declaration.
///
/// @link https://goo.gl/Ru1bKP
///
/// @argument {string} $font-family
///
/// @argument {string} $file-path
///
/// @argument {boolean} $asset-pipeline
///
/// @argument {list} $file-formats
///
/// @return {list}
///
/// @require {function} _contains
///
/// @access private

@function _font-source-declaration($font-family, $file-path, $asset-pipeline, $file-formats) {
  $src: ();

  $formats-map: (
    eot: '#{$file-path}.eot?#iefix'format('embedded-opentype'),
    woff2: '#{$file-path}.woff2'format('woff2'),
    woff: '#{$file-path}.woff'format('woff'),
    ttf: '#{$file-path}.ttf'format('truetype'),
    svg: '#{$file-path}.svg##{$font-family}'format('svg')
  );

  @each $key, $values in $formats-map {
    @if _contains($file-formats, $key) {
      $file-path: nth($values, 1);
      $font-format: nth($values, 2);

      @if $asset-pipeline == true {
        $src: append($src, font-url($file-path) $font-format, comma);
      } @else {
        $src: append($src, url($file-path) $font-format, comma);
      }
    }
  }

  @return $src;
}
