@charset "UTF-8";

// scss-lint:disable ElsePlacement

/// Transforms shorthand to its shortest possible form.
///
/// @argument {list} $values
///   List of directional values.
///
/// @example scss
///   $values: _compact-shorthand(10px 20px 10px 20px);
///
///   // Output
///   $values: 10px 20px;
///
/// @return {list}
///
/// @access private

@function _compact-shorthand($values) {
  $output: null;

  $a: nth($values, 1);
  $b: if(length($values) < 2, $a, nth($values, 2));
  $c: if(length($values) < 3, $a, nth($values, 3));
  $d: if(length($values) < 2, $a, nth($values, if(length($values) < 4, 2, 4)));

  @if $a == 0 {
    $a: 0;
  }
  @if $b == 0 {
    $b: 0;
  }
  @if $c == 0 {
    $c: 0;
  }
  @if $d == 0 {
    $d: 0;
  }

  @if $a == $b and $a == $c and $a == $d {
    $output: $a;
  } @else if $a == $c and $b == $d {
    $output: $a $b;
  } @else if $b == $d {
    $output: $a $b $c;
  } @else {
    $output: $a $b $c $d;
  }

  @return $output;
}
