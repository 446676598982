@charset "UTF-8";

/// Provides a concise, one-line method for setting an element’s positioning
/// properties: `position`, `top`, `right`, `bottom` and `left`. Use a `null`
/// value to “skip” an edge of the box.
///
/// @argument {string} $position
///   A CSS position value.
///
/// @argument {list} $box-edge-values
///   List of lengths; accepts CSS shorthand.
///
/// @example scss
///   .element {
///     @include position(relative, 0 null null 10em);
///   }
///
///   // CSS Output
///   .element {
///     left: 10em;
///     position: relative;
///     top: 0;
///   }
///
/// @example scss
///   .element {
///     @include position(absolute, 0);
///   }
///
///   // CSS Output
///   .element {
///     position: absolute;
///     top: 0;
///     right: 0;
///     bottom: 0;
///     left: 0;
///   }
///
/// @require {function} _is-length
///
/// @require {function} _unpack-shorthand

@mixin position($position, $box-edge-values) {
  $box-edge-values: _unpack-shorthand($box-edge-values);
  $offsets: (
    top: nth($box-edge-values, 1),
    right: nth($box-edge-values, 2),
    bottom: nth($box-edge-values, 3),
    left: nth($box-edge-values, 4)
  );

  position: $position;

  @each $offset, $value in $offsets {
    @if _is-length($value) {
      #{$offset}: $value;
    }
  }
}
